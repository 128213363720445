import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Button from '../components/common/Button';
import Card from '../components/common/Card';
import ContentContainer from '../components/common/ContentContainer';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import ContentfulContent from './../components/common/ContentfulContent';
import { extractContent } from './../util';
import { Link } from 'gatsby';

type Service = {
  id: string;
  image: {
    fluid: {
      src: string;
    };
  };
  name: string;
  description: {
    description: string;
  };
};

const Services: React.FC = () => {
  const data = useStaticQuery(graphql`
    {
      servicesImage: file(relativePath: { eq: "images/about.png" }) {
        childImageSharp {
          sizes(maxWidth: 600) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      allContentfulService(
        filter: { node_locale: { eq: "en-US" } }
        sort: { order: ASC, fields: pagePosition }
      ) {
        edges {
          node {
            image {
              fluid {
                src
              }
            }
            id
            name
            description {
              description
            }
          }
        }
      }
    }
  `);

  const services = extractContent(data.allContentfulService);

  return (
    <Layout>
      <SEO title="Services" />
      <ContentContainer>
        <div className="text-center self-center">
          <h1 className="font-bold text-primary text-3xl mt-6">
            Partnering with Advancing Literacy
          </h1>
        </div>
        <div className="mt-4 w-full sm:w-2/3 mx-auto">
          <div className="flex flex-col sm:flex-row">
            <div className="mb-10 sm:mb-0 w-full">
              <p className="text-gray-600 mt-4 mr-4 text-xl text-center">
                Are you interested in learning more about the work we do at
                Advancing Literacy? In addition to our events, we offer a
                variety of support services for teachers, literacy coaches, and
                school and district leaders to begin or continue professional
                development with workshop teaching, comprehensive literacy
                support, and whole school or district literacy reform.
              </p>
            </div>
          </div>
        </div>
        <section className="mt-20">
          <h2 className="text-primary font-bold text-3xl mb-6">Our Services</h2>
          {services.map((s: Service, i: number) => (
            <section className="mb-6" key={i}>
              <Card>
                <h3 className="text-primary font-bold text-xl">{s.name}</h3>
                <div className="my-10 flex flex-col sm:flex-row">
                  <div className="w-full sm:w-1/4">
                    <img
                      className="rounded-lg w-full"
                      src={s.image?.fluid?.src}
                    />
                    {s.name !== 'Asynchronous Courses' ? (
                      <div className="mt-4 text-center mb-2 sm:mb-0">
                        <Link to="/contact">
                          <Button text="Apply for Services" />
                        </Link>
                      </div>
                    ) : null}
                    {s.name === 'Asynchronous Courses' ? (
                      <div className="mt-4 text-center mb-2 sm:mb-0">
                        <Link to="/asynchronous-courses">
                          <Button text="View Courses" />
                        </Link>
                      </div>
                    ) : null}
                  </div>
                  <div className="w-full sm:w-3/4 p-2 sm:p-6 sm:pt-0 typography content-body">
                    <ContentfulContent content={s.description.description} />
                    {s.name === 'Professional Development with Schools' && (
                      <div className="mt-9 flex">
                        {/* <div className="mr-2">
                          <Link
                            to="https://drive.google.com/file/d/1NQcFCXUBpk_dmFuBdvEKpll_C6rzvOz-/view"
                            target="_blank"
                          >
                            <Button text="Download Full Partner Schools Brochure" />
                          </Link>
                        </div> */}
                        <div className="mr-2">
                          <Link
                            to="https://drive.google.com/file/d/1gBdaTFFdSLEGcSZ6TOFtsBkyXevVA1iD/view?usp=share_link"
                            target="_blank"
                          >
                            <Button text="Download National Partner Schools Brochure" />
                          </Link>
                        </div>
                      </div>
                    )}
                    {s.name === 'Local Summer Institutes' && (
                      <div className="mt-9 flex">
                        <div className="mr-2">
                          <Link
                            to="https://drive.google.com/file/d/1jehNx32hZhtHs7S_XeAOEny9j1mKww8r/view?usp=share_link"
                            target="_blank"
                          >
                            <Button text="Download Local Summer Institutes Brochure" />
                          </Link>
                        </div>
                      </div>
                    )}
                    {s.name === 'Foundational Reading Skills Online Course' && (
                      <div className="mt-9 flex">
                        <div className="mr-2">
                          <Link
                            to="https://drive.google.com/file/d/1bSdDnJTU_mudgx1IpmpwgMHdVn4gp4Lp/view?usp=share_link"
                            target="_blank"
                          >
                            <Button text="Download Foundational Reading Skills Online Course Overview" />
                          </Link>
                        </div>
                      </div>
                    )}
                    {s.name ===
                      'Foundational Reading Skills Online Modules' && (
                      <div className="mt-9 flex">
                        <div className="mr-2">
                          <Link
                            to="https://forms.gle/EAcP5uw6J6ebzkR46"
                            target="_blank"
                          >
                            <Button text="Pre-register Here" />
                          </Link>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </Card>
            </section>
          ))}
        </section>
      </ContentContainer>
    </Layout>
  );
};

export default Services;
